import { gql, useQuery } from "@apollo/client";
import {
  GetCohortDetailsModalQuery,
  GetCohortDetailsModalQueryVariables,
  GradeLevel,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { getGradeLevelText } from "@utils/labels";
import clsx from "clsx";
import { CohortSpecialDaysCard } from "components/cohorts/CohortDetailsPage/components/CohortDetailsTabs/Tabs/CohortDetailsTab/Cards";
import { CohortStrandStandardCard } from "components/cohorts/CohortDetailsPage/components/CohortDetailsTabs/Tabs/CohortDetailsTab/Cards/CohortStrandStandardCard/CohortStrandStandardCard";
import {
  CohortAssignmentSubjectBadge,
  EngagementAssignmentRoleBadge,
  IconText,
  MinimalCopyButton,
  Spinner,
} from "components/shared";
import { getNormalizedShortFullDateRangeText } from "helpers";
import { sortBy } from "lodash";
import { useEffect, useRef } from "react";

const GET_COHORT_DETAILS_MODAL = gql`
  query GetCohortDetailsModal($cohortId: ID!) {
    cohort(id: $cohortId) {
      id
      name
      endDate
      startDate
      meetingRoom
      instructionNotes
      instructionLevel
      staffAssignments {
        cohortSubject
        cohortAssignmentRole
        user {
          id
          fullName
        }
      }
      engagement {
        id
        name
        endDate
        startDate
        staffAssignments {
          engagementAssignmentRole
          user {
            id
            fullName
          }
        }
        organization {
          id
          name
        }
      }
      ...CohortSpecialDaysCard_Cohort
    }
  }
  ${CohortSpecialDaysCard.fragments.cohort}
`;

type Props = {
  cohortId: string;
  openCohortScheduleModal: () => void;
  setCohortName: (name: string) => void;
};

export const CohortDetailsModalBody = ({
  cohortId,
  setCohortName,
  openCohortScheduleModal,
}: Props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const { data, loading } = useQuery<
    GetCohortDetailsModalQuery,
    GetCohortDetailsModalQueryVariables
  >(GET_COHORT_DETAILS_MODAL, {
    skip: !cohortId,
    variables: { cohortId },
    fetchPolicy: "network-only",
    onError: (err) => fetchErrToast("this cohort's details.", err),
  });

  useEffect(() => {
    setCohortName(data?.cohort?.name ?? "");
  }, [data?.cohort?.name, setCohortName]);

  const { cohort } = data ?? {};
  const { startDate, endDate, engagement, instructionLevel } = cohort ?? {};
  const { id: engId, name: engName, organization } = engagement ?? {};

  const meetingIdMatch = cohort?.meetingRoom?.match(/meeting_([A-Za-z0-9]*)/);
  const extractedMeetingID = meetingIdMatch ? meetingIdMatch[1] : false;

  return !loading && data && engagement && organization && cohort ? (
    <div className="flex flex-col gap-y-3 p-2 pt-2" ref={modalRef}>
      <div className="grid grid-cols-2 gap-x-3">
        <div className="flex flex-col gap-y-2 h-full">
          <label className="font-semibold text-lg text-slate-700 pl-1">
            Engagement Details
          </label>
          <div
            className={clsx(
              "flex flex-col gap-y-3 p-4 rounded-lg bg-slate-50",
              "border h-full shadow-engagement-card border-gray-300"
            )}
          >
            {sortBy(
              engagement.staffAssignments,
              "engagementAssignmentRole"
            ).map(({ user, engagementAssignmentRole }) => (
              <IconText
                key={`${user.id}-${engagementAssignmentRole}`}
                icon="user"
                child={
                  <div className="w-full flex justify-between items-center gap-x-3">
                    {user.fullName}
                    <EngagementAssignmentRoleBadge
                      role={engagementAssignmentRole}
                      mode="tiny"
                    />
                  </div>
                }
              />
            ))}
            <IconText icon="organization" child={organization.name} />
            <IconText icon="engagement" child={engName} />
            <IconText icon="key" child={engId} />
            <IconText
              icon="calendar"
              child={getNormalizedShortFullDateRangeText(
                engagement.startDate,
                engagement.endDate
              )}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-2 h-full">
          <label className="font-semibold text-lg text-slate-700 pl-1">
            Cohort Details
          </label>
          <div
            className={clsx(
              "flex flex-col gap-y-3 p-4 rounded-lg bg-slate-50",
              "border h-full shadow-engagement-card border-gray-300"
            )}
          >
            {sortBy(cohort.staffAssignments, "cohortAssignmentRole").map(
              ({ user, cohortAssignmentRole, cohortSubject }) => (
                <IconText
                  key={`${user.id}-${cohortAssignmentRole}`}
                  icon="user"
                  child={
                    <div className="w-full flex justify-between items-center gap-x-3">
                      {user.fullName}
                      <div className="flex gap-x-1">
                        <CohortAssignmentSubjectBadge
                          cohortSubject={cohortSubject}
                        />
                      </div>
                    </div>
                  }
                />
              )
            )}

            <IconText
              icon="grade"
              child={getGradeLevelText(instructionLevel ?? GradeLevel.Unknown)}
            />

            <IconText icon="key" child={cohort.id} />

            <IconText
              icon="calendar"
              child={
                <button
                  className={clsx(
                    "flex text-blue-500 items-center text-sm font-medium",
                    "hover:underline hover:underline-offset-2"
                  )}
                  onClick={openCohortScheduleModal}
                >
                  {getNormalizedShortFullDateRangeText(startDate, endDate)}
                </button>
              }
            />
          </div>
        </div>
      </div>

      {extractedMeetingID && (
        <div className="flex items-center gap-x-[6px] px-2 text-base font-semibold text-slate-700">
          Meeting ID:{" "}
          <span className="text-slate-600 font-medium text-sm">
            {extractedMeetingID}
          </span>
          <MinimalCopyButton copyValue="extractedMeetingID" label="" />
        </div>
      )}

      {cohort.instructionNotes && (
        <div
          className={clsx(
            "flex justify-between items-center gap-x-8 px-3 py-2",
            "rounded-lg bg-gray-50 border-gray-300",
            "border h-full border-gray-200"
          )}
        >
          <div className="text-base font-semibold text-slate-700 min-w-[160px]">
            Instructional Notes
          </div>
          <div
            className={clsx(
              "flex px-3 py-1 border border-gray-300 rounded-md",
              "bg-white min-w-[50%] text-gray-500 leading-tight"
            )}
            key="instructionalNotes"
          >
            {cohort.instructionNotes}
          </div>
        </div>
      )}

      <CohortSpecialDaysCard
        cohort={cohort}
        className="shadow-none"
        hideEmpty
        readOnly
      />

      <CohortStrandStandardCard
        cohortId={cohortId}
        cohortStartDate={startDate}
        cohortEndDate={endDate}
      />
    </div>
  ) : (
    <div className="w-full h-[40vh] flex flex-center pb-12">
      <Spinner size={10} color="text-blue-600" />
    </div>
  );
};
