import { Icon, Tooltip } from "@shared";
import clsx from "clsx";
import { CohortsScheduleCalendarModal } from "components/cohorts/CohortsScheduleCalendar";
import { useState } from "react";
import { CohortDetailsModal } from "../CohortDetailsModal";

type Props = { cohortId: string; className?: string };

export const CohortDetailsOpenIcon = ({ cohortId, className }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);

  return (
    <>
      <div
        className={clsx("flex h-full items-center cursor-pointer", className)}
        onClick={() => setShowDetails(true)}
      >
        <Tooltip
          className="w-full h-full"
          tooltipProps={{ place: "right" }}
          content="Cohort Information Card"
        >
          <Icon icon="infoFile" />
        </Tooltip>
      </div>

      <CohortDetailsModal
        show={showDetails}
        cohortId={cohortId}
        onClose={() => setShowDetails(false)}
        openCohortScheduleModal={() => setShowSchedule(true)}
      />

      <CohortsScheduleCalendarModal
        show={showSchedule}
        cohortIds={[cohortId]}
        closeModal={() => setShowSchedule(false)}
      />
    </>
  );
};
