import { Modal } from "components/shared";
import { useRef, useState } from "react";
import { CohortDetailsModalBody } from "./components/CohortDetailsModalBody";

type Props = {
  show: boolean;
  cohortId: string;
  onClose: () => void;
  openCohortScheduleModal: () => void;
};

export const CohortDetailsModal = ({
  show,
  cohortId,
  onClose,
  openCohortScheduleModal,
}: Props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [cohortName, setCohortName] = useState<string>("");

  return (
    <Modal
      show={show}
      width="xxlarge"
      initialFocus={modalRef}
      icon={<Modal.Icon icon="infoFile" />}
      title={`${cohortName} Information Card`}
      onClose={onClose}
      onDismissClick={onClose}
    >
      <CohortDetailsModalBody
        cohortId={cohortId}
        setCohortName={setCohortName}
        openCohortScheduleModal={openCohortScheduleModal}
      />
    </Modal>
  );
};
