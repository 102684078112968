/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useApolloClient, useSubscription } from "@apollo/client";
import {
  Engagement,
  MentorTeacherDashboardTeacherAttendanceSubscription,
  MentorTeacherDashboardTeacherAttendanceSubscriptionVariables,
} from "@generated/graphql";

export const MENTOR_TEACHER_ATTENDANCE_DASHBOARD = gql`
  subscription MentorTeacherDashboardTeacherAttendance($engagementIds: [ID!]!) {
    teacherAttendanceUpdated(engagementIds: $engagementIds) {
      staffRole
      staffUserId
      updatedById
      attendanceId
      engagementId
      substituteId
      substituteName
      attendanceNote
      attendanceStatus
      attendanceAutomaticallyUpdated
    }
  }
`;

export function MentorTeacherDashboardTeacherAttendanceSubscriptionComponent({
  engagementIds,
}: {
  engagementIds: Engagement["id"][];
}) {
  const client = useApolloClient();

  useSubscription<
    MentorTeacherDashboardTeacherAttendanceSubscription,
    MentorTeacherDashboardTeacherAttendanceSubscriptionVariables
  >(MENTOR_TEACHER_ATTENDANCE_DASHBOARD, {
    variables: { engagementIds },
    onData: ({ data }) => {
      if (!data.data?.teacherAttendanceUpdated) return;
      const cache: { [key: string]: any } = client.cache.extract();

      const { teacherAttendanceUpdated } = data.data;
      const {
        staffRole,
        updatedById,
        staffUserId,
        engagementId,
        attendanceId,
        substituteId,
        attendanceNote,
        substituteName,
        attendanceStatus,
        attendanceAutomaticallyUpdated,
      } = teacherAttendanceUpdated;

      const keyPrefix = "MentorDashSessionAssignmentRow:";
      const matchingKeys = Object.keys(cache).filter((key) =>
        key.startsWith(`${keyPrefix}${engagementId}:${staffUserId}`)
      );

      // Locate the item with the correct `attendanceId` & `role`
      let targetKey: string | undefined;
      for (const key of matchingKeys) {
        const item = cache[key];
        if (
          item?.attendanceId === attendanceId &&
          item?.staffRole === staffRole
        ) {
          targetKey = key;
          break;
        }
      }

      if (!targetKey) return;

      client.cache.modify({
        id: targetKey,
        fields: {
          updatedById: () => updatedById ?? null,
          attendanceStatus: () => attendanceStatus,
          substituteId: () => substituteId ?? null,
          attendanceNote: () => attendanceNote ?? null,
          substituteName: () => substituteName ?? null,
          attendanceAutomaticallyUpdated: () => attendanceAutomaticallyUpdated,
        },
      });
    },
    onError: (error) => console.error("Subscription error:", error),
  });

  return null;
}
